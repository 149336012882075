
.slg-a-button--min-size {
  @include suiMediaMin($sui-breakpoint-sm) {
    min-width: rem(180);
  }
}

.slg-a-button--outline-white {
  @include suiButtonColor(
    $background: transparent,
    $border-color: white,
    $color: white,
    $background-hover: white,
    $border-color-hover: white,
    $color-hover: attr(data-color),
    $color-contextual: $sui-color-text,
    $color-focus-effect: $sui-color-primary
  );

  &::before {
    content: attr(data-label);
    font-size: $sui-font-size-md;
  }

  a:hover & {
    &::before {
      color: var(--dataColor);
    }
  }
}
