.slg-m-card {
  height: rem(370);
  border-radius: rem(4);
  overflow: hidden;
  background-position: rem(62) rem(44);
  background-repeat: no-repeat;
  background-size: rem(520);
  transition: 0.8s background-position ease, 0.4s background-size ease;

  &.as--skeleton {
    border: $sui-size-border dashed $sui-color-border;
    box-shadow: none;
  }

  &:hover {
    background-position: rem(58) rem(40);
    background-size: rem(510);
  }

  &__content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: $sui-space-lg;
  }

  &__title {
    color: white;
    font-size: $sui-font-size-lg;

    small {
      display: block;
      text-transform: uppercase;
      font-family: $sui-font-family-secondary;
      font-size: $sui-font-size-sm;
    }
  }

  &__action {
    margin-top: auto;
  }
}
