.slg-l-page-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $sui-space-md;
}

.slg-c-sso-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: $sui-color-text-light;

  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $sui-color-border;
  }

  &:not(:empty) {
    &::before {
      margin-right: rem(10);
    }
    &::after {
      margin-left: rem(10);
    }
  }
}