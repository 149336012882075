@import "~saagie-ui/src/scss/index";
// -------------------------
//    Layout
// -------------------------
@import "layout/page-loader";

// -------------------------
//    Atoms
// -------------------------
@import "atoms/button";

// -------------------------
//    Molecules
// -------------------------
@import "molecules/card";

.sui-o-topbar {
  display: none;
}

#product-info-accordion:before,
#product-info-accordion:after {
  content: none;
}
.sui-l-offline-splitted__content {
  z-index: 1;
}